import React from "react";
import image1 from "../../../../assets/housing/house1.jpg";
import image2 from "../../../../assets/housing/house2.jpg";
import image3 from "../../../../assets/housing/house3.jpg";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import {
  useGetAdminAllhousingQuery,
  useGetAllhousingQuery,
} from "../../../../app/features/housing/housingApi";
import { useGetAllCityQuery } from "../../../../app/features/account/accountApi";
import LoadingUi from "../../../../components/LoadingUi/LoadingUi";
import NoData from "../../../../components/NoData/NoData";

const AllHousung = () => {
  const {
    data: housing,
    error: housingError,
    loading: housingLoading,
  } = useGetAdminAllhousingQuery();
  const {
    data: cityData,
    error: cityDataError,
    loading: cityDataLoading,
  } = useGetAllCityQuery();

  const pendingData = housing?.results?.filter(
    (item) => item?.status === "pending"
  );
  // console.log(pendingData);

  const getCityName = (cityId) => {
    const city = cityData?.results.find((city) => city.id === cityId);
    return city?.name;
  };

  if (housingLoading && cityDataLoading) {
    return <LoadingUi />;
  }
  return (
    <div>
      <Helmet>
        <title>Dashboard || Housing Request </title>
      </Helmet>
      <div className="py-8 px-5">
        <h3 className="text-2xl font-semibold">Housing Request</h3>
      </div>
      <div>
        {pendingData?.length > 0 ? (
          <>
            <div className="overflow-x-auto">
              <table className="min-w-full bg-white border border-gray-200">
                <thead>
                  <tr>
                    <th className="px-6 py-3 border-b text-left text-gray-600 font-semibold">
                      Image
                    </th>
                    <th className="px-6 py-3 border-b text-left text-gray-600 font-semibold">
                      Name
                    </th>
                    <th className="px-6 py-3 border-b text-left text-gray-600 font-semibold">
                      Price
                    </th>
                    {/* <th className="px-6 py-3 border-b text-left text-gray-600 font-semibold"></th> */}
                    <th className="px-6 py-3 border-b text-left text-gray-600 font-semibold">
                      City
                    </th>
                    <th className="px-6 py-3 border-b text-left text-gray-600 font-semibold">
                      Type
                    </th>
                    <th className="px-6 py-3 border-b text-left text-gray-600 font-semibold">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {pendingData?.map((apartment) => (
                    <tr key={apartment?.id} className="border-b">
                      <td className="px-6 py-4">
                        <img
                          src={apartment.images[0]?.image}
                          alt={apartment.name}
                          className="w-16 h-16 object-cover rounded-md"
                        />
                      </td>
                      <td className="px-6 py-4 text-gray-700">
                        {apartment.name}
                      </td>
                      <td className="px-6 py-4 text-gray-700">
                        ${apartment?.details?.rent_per_month}
                      </td>
                      {/* <td className="px-6 py-4 text-gray-700">{apartment.rooms}</td> */}
                      <td className="px-6 py-4 text-gray-700">
                        {" "}
                        {getCityName(apartment?.city)}{" "}
                      </td>
                      <td className="px-6 py-4 text-gray-700">
                        {apartment.types}
                      </td>
                      <td className="px-6 py-4">
                        <Link
                          to={`/dashboard/housing/${apartment?.id}`}
                          target="_blank"
                        >
                          <button className="px-4 py-2 text-sm text-white bg-blue-500 hover:bg-blue-600 rounded">
                            View Details
                          </button>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <>
            <NoData />
          </>
        )}
      </div>
    </div>
  );
};

export default AllHousung;
