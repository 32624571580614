import { baseApi } from "../../api/baseApi";

const housingApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    createHousing: builder.mutation({
      query: (data) => {
        return {
          url: `/housing/apartment/creator/`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["housing"],
    }),
    deleteCreatorHousingById: builder.mutation({
      query: (id) => ({
        url: `/housing/apartment/creator/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["housing"],
    }),
    updateHousingById: builder.mutation({
      query: ({ updateData, id }) => ({
        url: `/housing/apartment/creator/${id}/`,
        method: "PATCH",
        body: updateData,
      }),
      invalidatesTags: ["housing"],
    }),

    getAdminAllhousing: builder.query({
      query: () => ({
        url: `/housing/apartment/admin/`, // Ensure backend expects this endpoint
        method: "GET",
      }),
      providesTags: "housing",
    }),
    getAdminAllhousingById: builder.query({
      query: (id) => ({
        url: `housing/apartment/admin/${id}/`, // Ensure backend expects this endpoint
        method: "GET",
      }),
      providesTags: "housing",
    }),
    updateContactHousingAdminById: builder.mutation({
      query: ({ id, data }) => ({
        url: `/housing/apartment/contact/admin/${id}/`, // Ensure backend expects this endpoint
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["housing"],
    }),

    updateHousingStatusById: builder.mutation({
      query: ({ id, data }) => ({
        url: `/housing/apartment/admin/${id}/`, // Ensure backend expects this endpoint
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["housing"],
    }),

    getAllhousing: builder.query({
      query: () => ({
        url: `/housing/apartment/creator/`, // Ensure backend expects this endpoint
        method: "GET",
      }),
      providesTags: "housing",
    }),
    getAllhousingById: builder.query({
      query: (id) => ({
        url: `/housing/apartment/creator/${id}/`, // Ensure backend matches this path
        method: "GET",
      }),
      providesTags: "housing",
    }),

    createHousingImage: builder.mutation({
      query: ({ id, formData }) => ({
        url: `/housing/apartment/${id}/image/`, // Ensure backend expects this endpoint
        method: "POST",
        body: formData, // FormData object with images
      }),
      invalidatesTags: ["HousingImage"], // Cache invalidation if necessary
    }),
    deleteHousingImage: builder.mutation({
      query: ({ aprtId, imgId }) => ({
        url: `/housing/apartment/${aprtId}/image/${imgId}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["housing"],
    }),

    //customer view
    getAllhousingCustomer: builder.query({
      query: () => ({
        url: `/housing/apartment/`, // Ensure backend matches this path
        method: "GET",
      }),
      providesTags: "housing",
    }),

    getAllhousingCustomerById: builder.query({
      query: (id) => ({
        url: `/housing/apartment/${id}/`, // Ensure backend matches this path
        method: "GET",
      }),
      providesTags: "housing",
    }),

    contactUsForHousing: builder.mutation({
      query: (data) => {
        return {
          url: `housing/apartment/contact/`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["Housing"],
    }),

    getMyHousingRequest: builder.query({
      query: () => ({
        url: "/housing/apartment/contact/",
        method: "GET",
      }),
      providesTags: "housing",
    }),
    getMyHousingRequestById: builder.query({
      query: (id) => ({
        url: `/housing/apartment/contact/${id}/`,
        method: "GET",
      }),
      providesTags: "housing",
    }),
    updateMyHousingRequestById: builder.mutation({
      query: ({ id, data }) => ({
        url: `/housing/apartment/contact/${id}/`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["housing"],
    }),
    deletMyHousingRequestById: builder.mutation({
      query: (id) => ({
        url: `/housing/apartment/contact/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["housing"],
    }),
  }),
});

export const {
  useCreateHousingMutation,
  useDeleteCreatorHousingByIdMutation,
  useUpdateHousingByIdMutation,
  useGetAllhousingQuery,
  useGetAdminAllhousingByIdQuery,
  useGetAdminAllhousingQuery,
  useUpdateContactHousingAdminByIdMutation,
  useGetAllhousingByIdQuery,
  useCreateHousingImageMutation,
  useDeleteHousingImageMutation,
  useUpdateHousingStatusByIdMutation,

  useGetAllhousingCustomerQuery,
  useGetAllhousingCustomerByIdQuery,
  useContactUsForHousingMutation,
  useGetMyHousingRequestQuery,
  useGetMyHousingRequestByIdQuery,
  useUpdateMyHousingRequestByIdMutation,
  useDeletMyHousingRequestByIdMutation,
} = housingApi;
