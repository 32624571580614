import React, { useState } from "react";
import image1 from "../../../assets/housing/house1.jpg";
import image2 from "../../../assets/housing/house2.jpg";
import image3 from "../../../assets/housing/house3.jpg";
import { IoLocationOutline } from "react-icons/io5";
import { FaEuroSign } from "react-icons/fa";
import { Gi3dStairs } from "react-icons/gi";
import { IoResize } from "react-icons/io5";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import { useGetAllhousingCustomerQuery } from "../../../app/features/housing/housingApi";
import { useGetAllCityQuery } from "../../../app/features/account/accountApi";
import LoadingUi from "../../../components/LoadingUi/LoadingUi";
import config from "../../../config";
import { Helmet } from "react-helmet";

import { setCurrentUser } from "../../../app/features/auth/authSlice";
import { useAppSelector } from "../../../app/hooks";

const HousingPages = () => {
  const [moveInDate, setMoveInDate] = useState(null);
  const [priceRange, setPriceRange] = useState(200);
  const navigate = useNavigate();

  const user = useAppSelector(setCurrentUser);
  // console.log(user);

  // Default price set at 1500
  const countries = [
    { value: "Finland", label: "Finland" },
    { value: "Bangladesh", label: "Bangladesh" },
  ];
  // const handlePriceChange = (event) => {
  //   setPriceRange(event.target.value);
  // };

  // Calculate percentage for the selected range (min: 500, max: 3000)
  const percentage = ((priceRange - 200) / (3000 - 200)) * 100;

  const [filters, setFilters] = useState({
    type: "", // full or shared
    priceRange: "",
    rooms: "",
    city: "", // new city filter
  });

  // console.log(filters);

  const {
    data: housing,
    loading: housingLoading,
    error: housingError,
  } = useGetAllhousingCustomerQuery();
  const {
    data: cityData,
    error: cityError,
    loading: cityLoading,
  } = useGetAllCityQuery();

  const cityLocation = cityData?.results?.map((country) => ({
    value: country.id,
    label: country.name,
  }));

  const getCityName = (cityId) => {
    const city = cityData?.results.find((city) => city.id === cityId);
    return city?.name;
  };

  // console.log(filters);

  // console.log(housing?.results);

  const handleFilterChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  const handlePriceChange = (e) => {
    const value = e.target.value;
    setPriceRange(value);
    setFilters((prevFilters) => ({
      ...prevFilters,
      priceRange: value,
    }));
  };

  const filteredApartments = housing?.results?.filter((apartment) => {
    return (
      (filters.type ? apartment?.types === filters?.type : true) &&
      (filters.priceRange
        ? apartment?.details?.rent_per_month <= filters.priceRange
        : true) &&
      (filters.rooms ? apartment?.details?.rooms <= filters.rooms : true) &&
      (filters.city ? apartment?.city === filters.city : true) &&
      (moveInDate
        ? new Date(apartment?.details?.rent_start_date) >= moveInDate
        : true)
      // (filters.availability
      //   ? apartment.availability === filters.availability
      //   : true) &&
      // (filters.city ? apartment.city === filters.city : true)
    );
  });
  
  // console.log(obfuscateId(11))

  const handleViewDetails = (id) => {
    const hashedId = CryptoJS.AES.encrypt(
      id.toString(),
      config.secretKey
    ).toString();
    const url = `/housing/${encodeURIComponent(hashedId)}`;
    window.open(url, "_blank");
  };

  const handleFindHouse = () => {
    if (!user?.email) {
      navigate("/sign-in", { state: "/student/findHouse" });
    } else {
      navigate("/student/findHouse");
    }
  };

  if (housingLoading && cityLoading) {
    return <LoadingUi />;
  }
  // console.log(filteredApartments);

  return (
    <div className=" bg-[#f5e2e2c0] ">
      <div className="flex flex-col md:flex-row gap-6 p-6  container mx-auto min-h-screen -z-10">
        <Helmet>
          <title>UniEdu || Housing </title>
        </Helmet>
        {/* Filter Section */}
        <div className="w-full md:w-1/4 bg-gradient-to-br from-white to-gray-50 p-6 rounded-xl shadow-lg border h-fit border-gray-200 overflow-y-scroll">
          <h2 className="text-2xl font-semibold mb-6 text-gray-800">
            Filter Apartments
          </h2>

          <div className="mb-5">
            <label className="block text-gray-700 font-medium mb-2">
              Apartment Type
            </label>
            <div className="flex flex-col space-y-2">
              <label className="flex items-center cursor-pointer">
                <input
                  type="radio"
                  name="type"
                  value=""
                  onChange={handleFilterChange}
                  className="hidden peer"
                />
                <span className="w-4 h-4 inline-block mr-2 border border-gray-300 rounded-full peer-checked:bg-blue-500 peer-checked:border-transparent"></span>
                All Types
              </label>
              <label className="flex items-center cursor-pointer">
                <input
                  type="radio"
                  name="type"
                  value="full"
                  onChange={handleFilterChange}
                  className="hidden peer"
                />
                <span className="w-4 h-4 inline-block mr-2 border border-gray-300 rounded-full peer-checked:bg-blue-500 peer-checked:border-transparent"></span>
                Full Apartment
              </label>
              <label className="flex items-center cursor-pointer">
                <input
                  type="radio"
                  name="type"
                  value="shared"
                  onChange={handleFilterChange}
                  className="hidden peer"
                />
                <span className="w-4 h-4 inline-block mr-2 border border-gray-300 rounded-full peer-checked:bg-blue-500 peer-checked:border-transparent"></span>
                Shared Apartment
              </label>
            </div>
          </div>

          <div className="mb-5">
            <label className="block text-gray-700 font-medium mb-2">
              Rooms
            </label>
            <div className="flex flex-col space-y-2">
              <label className="flex items-center cursor-pointer">
                <input
                  type="radio"
                  name="rooms"
                  value=""
                  onChange={handleFilterChange}
                  className="hidden peer"
                />
                <span className="w-4 h-4 inline-block mr-2 border border-gray-300 rounded-full peer-checked:bg-blue-500 peer-checked:border-transparent"></span>
                All
              </label>
              <label className="flex items-center cursor-pointer">
                <input
                  type="radio"
                  name="rooms"
                  value="1"
                  onChange={handleFilterChange}
                  className="hidden peer"
                />
                <span className="w-4 h-4 inline-block mr-2 border border-gray-300 rounded-full peer-checked:bg-blue-500 peer-checked:border-transparent"></span>
                1 Rooms
              </label>
              <label className="flex items-center cursor-pointer">
                <input
                  type="radio"
                  name="rooms"
                  value="2"
                  onChange={handleFilterChange}
                  className="hidden peer"
                />
                <span className="w-4 h-4 inline-block mr-2 border border-gray-300 rounded-full peer-checked:bg-blue-500 peer-checked:border-transparent"></span>
                2 Rooms
              </label>
              <label className="flex items-center cursor-pointer">
                <input
                  type="radio"
                  name="rooms"
                  value="3"
                  onChange={handleFilterChange}
                  className="hidden peer"
                />
                <span className="w-4 h-4 inline-block mr-2 border border-gray-300 rounded-full peer-checked:bg-blue-500 peer-checked:border-transparent"></span>
                3 Rooms
              </label>
            </div>
          </div>

          {/* Calendar for Move-In Date */}
          <div className="mb-5 w-full">
            <label className="block text-gray-700 font-medium mb-2">
              Move-In Date
            </label>
            <DatePicker
              selected={moveInDate}
              onChange={(date) => setMoveInDate(date)} // Update the moveInDate state
              dateFormat="yyyy/MM/dd"
              placeholderText="Select a date"
              className="w-full p-3 bg-gray-50 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-200 ease-in-out"
            />
          </div>

          <div className="mb-5">
            <label className="block text-gray-700 font-medium mb-2">City</label>

            <div>
              <Select
                className="basic-single"
                classNamePrefix="select"
                defaultValue={cityLocation?.value}
                name="city"
                onChange={(selectedOption) => {
                  setFilters({
                    ...filters,
                    city: selectedOption?.value,
                  });
                }}
                options={cityLocation}
              />
            </div>
          </div>

          <div className="mb-5">
            <label className="block text-gray-700 font-medium mb-2">
              Price Range
            </label>
            <input
              type="range"
              name="priceRange"
              min="200" // Set minimum value to 200
              max="3000"
              step="100"
              value={priceRange}
              onChange={handlePriceChange}
              className="w-full h-2 rounded-lg appearance-none cursor-pointer"
              style={{
                background: `linear-gradient(to right, #3b82f6 ${percentage}%, #e5e7eb ${percentage}%)`,
              }}
            />
            <div className="mt-2 text-gray-700 text-sm">
              Up to ${priceRange}
            </div>
          </div>

          <div className=" mt-6">
            <h1 className="text-xl text-btnColor-400 font-semibold">
              Request us for a housing
            </h1>
            <button
              onClick={handleFindHouse}
              className="mt-2 w-[150px] py-2 text-white rounded text-sm bg-btnColor-400"
            >
              Go For Request{" "}
            </button>
          </div>
        </div>

        {/* Cards Section */}
        <div className="w-full md:w-3/4    grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 ">
          {filteredApartments?.map((apartment) => (
            <div
              key={apartment.id}
              className="bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-2xl transition-shadow duration-300 h-[390px]"
            >
              <Swiper
                navigation={true}
                pagination={{ clickable: true }}
                modules={[Navigation, Pagination]}
                className="mySwiper"
              >
                {apartment?.images.map((image, index) => (
                  <SwiperSlide key={index}>
                    <img
                      src={image?.image}
                      alt={`Product Image ${index + 1}`}
                      className="w-full h-[200px] object-cover"
                    />
                  </SwiperSlide>
                ))}
              </Swiper>

              <div
                className="cursor-pointer"
                onClick={() => handleViewDetails(apartment?.id)}
              >
                <div className="p-6 ">
                  <h3 className="text-xl font-bold text-gray-700">
                    {apartment.name}
                  </h3>
                  <p className=" text-gray-600">
                    <span className="text-gray-800 font-light flex items-center  gap-1 text-sm mt-1">
                      <IoLocationOutline />
                      {apartment.location}, {getCityName(apartment?.city)}
                    </span>
                  </p>
                  <div className="mt-2 text-gray-600 flex justify-between">
                    <span className="text-gray-600 font-light flex items-center gap-2">
                      <FaEuroSign /> {apartment?.details.rent_per_month} /{" "}
                      <span className="text-sm font-light">month</span>
                    </span>
                    <span className="text-gray-800 flex items-center gap-1">
                      <Gi3dStairs /> {apartment?.details?.floor} floor
                    </span>
                  </div>
                  <div className="mt-2 text-gray-600 flex justify-between">
                    <span className="text-gray-600 font-light flex items-center gap-2">
                      <IoResize />{apartment?.apartment_size} m&sup2;
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HousingPages;
