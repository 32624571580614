import React, { useEffect } from "react";
import image1 from "../../../assets/housing/house1.jpg";
import image2 from "../../../assets/housing/house2.jpg";
import image3 from "../../../assets/housing/house3.jpg";
import { IoLocationOutline } from "react-icons/io5";
import { FaEuroSign } from "react-icons/fa";
import { Gi3dStairs } from "react-icons/gi";
import { IoResize } from "react-icons/io5";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import { useGetAllhousingQuery } from "../../../app/features/housing/housingApi";
import {
  useGetAllCityQuery,
  useGetCountryQuery,
} from "../../../app/features/account/accountApi";
import LoadingUi from "../../../components/LoadingUi/LoadingUi";
import { Helmet } from "react-helmet";
const MyHousing = () => {
  const {
    data: housing,
    error: housingError,
    loading: housingLoading,
    refetch: housingRefetch,
  } = useGetAllhousingQuery();
  const {
    data: cityData,
    error: cityError,
    loading: cityLoading,
  } = useGetAllCityQuery();
  const {
    data: countryData,
    error: countryError,
    loading: countryLoading,
  } = useGetCountryQuery();
  // console.log(city);

  // console.log(housing?.results);

  const getCityName = (cityId) => {
    const city = cityData?.results.find((city) => city.id === cityId);
    return city?.name;
  };
  const getCountryName = (cityId) => {
    const city = countryData?.results.find((city) => city.id === cityId);
    return city?.name;
  };

  useEffect(() => {
    housingRefetch();
  }, [housingLoading]);

  if (housingLoading) {
    return <LoadingUi />;
  }

  return (
    <div className="flex flex-col md:flex-row gap-6 p-6  bg-[#f5e2e2c0] min-h-screen -z-10 ">
      <Helmet>
        <title>UniEdu || My Housing</title>
      </Helmet>
      <div className="w-full md:max-w-3/4    grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 container mx-auto">
        {housing?.results?.map((apartment) => (
          <div
            key={apartment.id}
            className="bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-2xl transition-shadow duration-300 h-[450px] "
          >
            <Swiper
              navigation={true}
              pagination={{ clickable: true }}
              modules={[Navigation, Pagination]}
              className="mySwiper"
            >
              {apartment?.images.map((image, index) => (
                <SwiperSlide key={index}>
                  <img
                    src={image?.image}
                    alt={`Product Image ${index + 1}`}
                    className="w-full h-[200px] object-cover"
                  />
                </SwiperSlide>
              ))}
            </Swiper>

            <Link to={`/student/my-housing/${apartment.id}`}>
              <div className="p-6 ">
                <h3 className="text-xl font-bold text-gray-700">
                  {apartment.name}
                </h3>
                <p className=" text-gray-600">
                  <span className="text-gray-800 font-light flex items-center  gap-1 text-sm mt-1">
                    <IoLocationOutline />
                    {apartment.location}, {getCityName(apartment?.city)} ,{" "}
                    {getCountryName(apartment?.country)}
                  </span>
                </p>
                <div className="mt-2 text-gray-600 flex justify-between">
                  <span className="text-gray-600 font-light flex items-center gap-2">
                    <FaEuroSign /> {apartment.details.rent_per_month} /{" "}
                    <span className="text-sm font-light">month</span>
                  </span>
                  <span className="text-gray-800 flex items-center gap-1">
                    <Gi3dStairs /> {apartment.details.floor} floor
                  </span>
                </div>
                <div className="mt-2 text-gray-600 flex justify-between">
                  <span className="text-gray-600 font-light flex items-center gap-2">
                    <IoResize /> {apartment.nearest[0].name} university from{" "}
                    {apartment.nearest[0].distance}
                  </span>
                </div>
                <p className="mt-2 text-gray-600">
                  Status:{" "}
                  <span
                    className={`font-semibold ${
                      apartment.status === "approved"
                        ? "text-green-500"
                        : "text-red-500"
                    }`}
                  >
                    <span className="capitalize">{apartment.status}</span>
                  </span>
                </p>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MyHousing;
