import React from 'react'
import { FaInbox } from 'react-icons/fa'

const NoData = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
    <div className="p-10 bg-white rounded-lg shadow-lg max-w-md transform hover:scale-105 transition-transform duration-300">
      <div className="flex flex-col items-center">
        <FaInbox className="text-gray-400 text-7xl mb-4" />
        <h2 className="text-2xl font-bold text-gray-800 mb-2">
          No Request Found
        </h2>
        <p className="text-gray-500 text-center mb-6">
          There's currently no data available. Please check back later.
        </p>
      
      </div>
    </div>
  </div>
  )
}

export default NoData
