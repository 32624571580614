import { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useForgetPasswordRequestMutation } from "../../app/features/auth/authApi";
import { useNavigate } from "react-router-dom";
import EmailVerify from "../../components/PopUp/EmailVerify";

const ForgetPasswordPage = () => {
  const navigate = useNavigate();
  const [forgetPasswordRequest] = useForgetPasswordRequestMutation();
  const [showEmailVerifyNotification, setShowEmailVerifyNotification] =
    useState(false);
  const handleEmailNotifiedClose = () => {
    setShowEmailVerifyNotification(false);
    navigate("/");
  };

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const toastId = toast.loading("Processing your request. Please wait...");
    try {
      const response = await forgetPasswordRequest(data).unwrap();
      // console.log(response);
      if (response?.message) {
        toast.success(response.message, { id: toastId });
        setShowEmailVerifyNotification(!showEmailVerifyNotification);
      }
      reset();
    } catch (error) {
      // console.log(error);
      if (error?.status === 400 && error?.data?.email) {
        toast.error(
          "No user found with this email address. Please double-check and enter a valid email.",
          { id: toastId, duration: 4000 }
        );
      } else if (error?.status === "FETCH_ERROR") {
        toast.error(
          "Network Error: Unable to connect to the server. Please check your internet connection or try again later.",
          { id: toastId, duration: 4000 }
        );
      } else {
        toast.error("Something went wrong. Please try again!! ", {
          id: toastId,
          duration: 4000,
        });
      }
    }
  };

  return (
    <div className="flex h-screen ">
      {showEmailVerifyNotification && (
        <EmailVerify
          onClose={handleEmailNotifiedClose}
          message={
            " A password reset link has been sent to your email. Please check your inbox and follow the link to reset your password."
          }
        />
      )}
      {/* Left Pane */}
      <div className="hidden lg:flex items-center justify-center flex-1 bg-white text-black"></div>
      {/* Right Pane */}
      <div className="w-full bg-gray-100 flex items-center justify-center">
        <div className="max-w-md w-full   bg-white px-10 py-20 rounded-2xl">
          <h1 className="text-3xl font-semibold mb-6 text-black text-center">
            Forget Password
          </h1>
          <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
            {/* Your form elements go here */}

            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                {...register("email", { required: true })}
                name="email"
                placeholder="Enter Your Email"
                className="mt-1 p-2 w-full border rounded-md focus:border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 transition-colors duration-300"
              />
              {errors.email && (
                <p className="text-sm mt-1 ml-2 text-red-500">
                  This field is required
                </p>
              )}
            </div>

            <div>
              <button
                aria-label="Sign In"
                type="submit"
                className="w-full bg-btnColor-400 text-white p-2 rounded-md hover:bg-orange-600  transition-colors duration-300 outline-none "
              >
                Confirm Email
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgetPasswordPage;
