import React, { useEffect, useState } from "react";
import {
  useDeletMyHousingRequestByIdMutation,
  useGetMyHousingRequestQuery,
} from "../../app/features/housing/housingApi";
import {
  useGetAllCityQuery,
  useGetCountryQuery,
  useGetProfileQuery,
} from "../../app/features/account/accountApi";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";

import { useAppSelector } from "../../app/hooks";
import { setCurrentUser } from "../../app/features/auth/authSlice";
import { Helmet } from "react-helmet";
// import Modal from './Modal';  // Assuming you have a Modal component

const FindMyHouse = () => {
  const {
    data: myHousing,
    loading: myHousingLoading,
    refetch: housingRefetch,
  } = useGetMyHousingRequestQuery();
  const {
    data: cityData,
    error: cityError,
    loading: cityLoading,
  } = useGetAllCityQuery();
  // console.log(cityData);

  const {
    data: countryData,
    error: countryError,
    loading: countryLoading,
  } = useGetCountryQuery();
  const [deletMyHousingRequestById] = useDeletMyHousingRequestByIdMutation();

  const [selectedUser, setSelectedUser] = useState(null); // State to store selected user data
  const [isModalOpen, setIsModalOpen] = useState(false);
  const user = useAppSelector(setCurrentUser);
  // console.log(user);

  const { data: myProfile, loading: myProfileLoading } = useGetProfileQuery();
  // console.log(myProfile);
  // console.log(myHousing);

  const filterByUser = myHousing?.results.filter(
    (user) => user?.submitted_by === myProfile?.id
  );

  // State to control modal visibility

  const getCityName = (cityId) => {
    const city = cityData?.results.find((city) => city.id === cityId);
    return city?.name;
  };

  const getCountryName = (cityId) => {
    const city = countryData?.results.find((city) => city.id === cityId);
    return city?.name;
  };

  const handleCardClick = (user) => {
    setSelectedUser(user);
    setIsModalOpen(true); // Open modal
  };

  const closeModal = () => {
    setIsModalOpen(false); // Close modal
  };

  const handleDeleteRequestById = async (id) => {
    // console.log(id);
    const toastId = toast.loading("Deleting Your Request");
    try {
      const res = await deletMyHousingRequestById(id).unwrap();
      // console.log(res);
      if (res === null) {
        toast.success("Delete Successfully", { id: toastId });
        housingRefetch();
        closeModal();
      }
    } catch (error) {
      toast.error("Something went wrong", { id: toastId });
    }
  };

  useEffect(() => {
    housingRefetch();
  }, [housingRefetch]);

  return (
    <div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 p-6 h-full">
      <Helmet>
        <title>UniEdu || My Requested Housing</title>
      </Helmet>
        {filterByUser?.map((user, index) => (
          <div
            key={index}
            className="bg-white p-4 rounded-lg shadow-lg hover:shadow-xl transition-shadow h-[350px] overflow-hidden cursor-pointer"
            onClick={() => handleCardClick(user)} // Open modal on card click
          >
            <h3 className="text-xl font-semibold mb-2 capitalize">
              {user.name}
            </h3>
            <p className="text-sm text-gray-500 mb-2">{user.email}</p>
            <p className="text-sm text-gray-500 mb-2">{user.phone}</p>
            <p className="text-sm text-gray-700 mb-2 capitalize">
              {user.location} , {getCityName(user?.city)},{" "}
              {getCountryName(user?.country)}
            </p>
            {/* <p className="text-sm text-gray-700 mb-4">
            <div className="ql-editor" dangerouslySetInnerHTML={{ __html: user?.description }} />
          </p> */}
            <div className="flex justify-between items-center">
              <span className="text-sm text-gray-500 capitalize">
                Gender: {user.gender}
              </span>
            </div>
            <div className="mt-4">
              <span className="inline-block text-sm text-gray-700 capitalize">
                Occupancy: {user.occupancy}
              </span>
            </div>
            <div className="mt-4">
              <span className="inline-block text-sm text-gray-700 capitalize">
                Accommodation: {user.accommodation}
              </span>
            </div>
            <div className="flex justify-between items-center mt-4">
              <h1>
                Status :
                <span
                  className={`text-sm ml-1 capitalize ${
                    user.status === "submitted"
                      ? "text-red-500"
                      : user.status === "progress"
                      ? "text-yellow-500"
                      : user.status === "finished"
                      ? "text-green-500"
                      : "text-gray-500"
                  }`}
                >
                   {user.status}
                </span>
              </h1>
            </div>
          </div>
        ))}
        {isModalOpen && (
          // <Modal user={selectedUser} closeModal={closeModal} />
          <div className="fixed inset-0 bg-gray-600 bg-opacity-60 flex justify-center items-center z-50">
            <div className="bg-white p-4 sm:p-6 rounded-lg shadow-lg max-w-sm w-full overflow-y-auto max-h-[90vh] sm:max-h-[80vh]">
              <h3 className="text-lg sm:text-2xl font-semibold mb-2 capitalize">
                {selectedUser.name}
              </h3>
              <p className="text-sm text-gray-500 mb-2">{selectedUser.email}</p>
              <p className="text-sm text-gray-500 mb-2">{selectedUser.phone}</p>
              <p className="text-sm text-gray-700 mb-2 capitalize">
                {selectedUser.location} {getCityName(selectedUser?.city)},{" "}
                {getCountryName(selectedUser?.country)}
              </p>
              <div>
                <h1 className="text-blue-500 text-sm sm:text-base capitalize">
                  Description
                </h1>
                <div
                  className="ql-editor text-sm sm:text-base capitalize"
                  dangerouslySetInnerHTML={{
                    __html: selectedUser?.description,
                  }}
                />
              </div>
              <div className="mt-4">
                <span className="inline-block text-sm sm:text-base text-gray-700 capitalize">
                  Gender: {selectedUser.gender}
                </span>
              </div>
              <div className="mt-4">
                <span className="inline-block text-sm sm:text-base text-gray-700 capitalize">
                single and married : {selectedUser.occupancy}
                </span>
              </div>
              <div className="mt-4">
                <span className="inline-block text-sm sm:text-base text-gray-700 capitalize">
                  Accommodation: {selectedUser.accommodation}
                </span>
              </div>

              {/* Buttons */}
              <div className="flex justify-end mt-4 gap-4">
                <button
                  className="bg-red-500 text-white p-2 rounded-full hover:bg-red-600 transition-colors"
                  onClick={() => handleDeleteRequestById(selectedUser.id)}
                >
                  <FaTrashAlt className="text-sm sm:text-xl" />{" "}
                  {/* Trash icon */}
                </button>
                <Link to={`/student/my-house-request/${selectedUser?.id}`}>
                  <button className="bg-blue-500 text-white p-2 rounded-full hover:bg-blue-600 transition-colors">
                    <FaEdit className="text-sm sm:text-xl" /> {/* Edit icon */}
                  </button>
                </Link>
              </div>

              {/* Close Modal Button */}
              <div className="mt-4 flex justify-end">
                <button
                  className="bg-gray-500 text-white px-3 py-2 sm:px-4 sm:py-2 rounded-md hover:bg-gray-600 transition-colors"
                  onClick={closeModal}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FindMyHouse;
