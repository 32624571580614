import { AiOutlineClose } from "react-icons/ai";
import logoImage from "../../assets/logo.png";
import { useState } from "react";

const EmailVerify = ({onClose,message}) => {
  //   const [isOpen, setIsOpen] = useState(true);

  //   const onClose = () => {
  //     isStateModalOpen(false);
  //   };

  //   if (!isOpen) return null;

  return (
     

    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
    <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full text-center">
      <div className="flex justify-center items-center py-3">
        <img src={logoImage} alt="" />
      </div>
      <h2 className="text-2xl font-semibold mb-4 text-gray-800">Email Verification</h2>
      <p className="  text-sm mb-6 text-center">
        {message}
      </p>
      <button
     onClick={onClose}
        className="bg-btnColor-400 w-[100px] hover:bg-[#7d05d8cc] duration-300 ease-in-out  text-white font-medium py-2 px-4 rounded"
      >
        Close
      </button>
    </div>
  </div>
  );
};

export default EmailVerify;
