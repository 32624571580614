import { baseApi } from "../../api/baseApi";

const authApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (userInfo) => ({
        url: "/account/login/",
        method: "POST",
        body: userInfo,
      }),
    }),
    registration: builder.mutation({
      query: (user) => ({
        url: "/account/register/",
        method: "POST",
        body: user,
      }),
    }),
    profile: builder.query({
      query: () => ({
        url: "/account/profile/",
        method: "GET",
      }),
      providesTags: ["profile"],
    }),
    forgetPasswordRequest: builder.mutation({
      query: (data) => ({
        url: "/account/password-reset-request/",
        method: "POST",
        body: data,
      }),
    }),
    forgetPasswordConfirm: builder.mutation({
      query: ({ password, token }) => ({
        url: `/account/password-reset-confirm/`,
        method: "POST",
        body: {
            password, // Password in body
        },
        headers: {
            Authorization: `Bearer ${token}`, // Token in headers
        },
      }
    ),
    }),
  }),
});

export const {
  useLoginMutation,
  useRegistrationMutation,
  useProfileQuery,
  useForgetPasswordRequestMutation,
  useForgetPasswordConfirmMutation,
} = authApi;
